<cb-dialog [dialogHeading]="dialogHeading()">
    <form class="flex-col flex"
          #itemForm="ngForm"
          id="itemForm"
          *cbWaitFor="mappedItem">
        <cb-select *ngIf="isAdd()"
                   label="House Area"
                   name="name"
                   [(ngModel)]="mappedItem.specGroupId"
                   [options]="houseAreas"
                   [disabled]="isLotSpecLocked$ | async"
                   [required]="true">
        </cb-select>
        <cb-slot-autocomplete-scroller *ngIf="isAdd()"
                                       class="cb-margin-top flex flex-row"
                                       label="Schedule Item Name"
                                       name="newChildSlot"
                                       style="min-width: 300px;"
                                       [(ngModel)]="selectedScheduleItem"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [specGroupId]="mappedItem.specGroupId"
                                       [disabled]="!(mappedItem?.specGroupId > 0)"
                                       (optionSelected)="scheduleItemSelected($event)"
                                       [required]="true"
                                       [excludeIds]="data?.slotIdListByGroupId[mappedItem.specGroupId]">
        </cb-slot-autocomplete-scroller>
        <cb-display-value-new *ngIf="isEdit()"
                              [value]="mappedItem.slotName"
                              label="Schedule Item Name">
        </cb-display-value-new>
        <cb-display-value-new *ngIf="isEdit()"
                              [value]="mappedItem.specGroupLabel"
                              label="House Area">
        </cb-display-value-new>
        <cb-display-value-new *ngIf="isQuoted()"
                              [value]="COST_TYPE_ENUM[mappedItem.costType]"
                              label="Cost Type">
        </cb-display-value-new>
        <cb-select *ngIf="!isQuoted()"
                   name="costType"
                   label="Cost Type"
                   [disabled]="isLotSpecLocked$ | async"
                   [options]="scheduleTemplateCostTypes"
                   [required]="true"
                   [(ngModel)]="mappedItem.costType"
                   (change)="clearProductForCostType()">
        </cb-select>
        <mat-card class="cb-margin-top-2x flex-col flex">
            <h4 class="mat-h4 cb-margin-bottom-2x">
                <b>Item Details</b>
            </h4>
            <cb-display-value-new *ngIf="isNotDescriptive()"
                                  [value]="mappedItem?.slotCategory?.id > 0 ? mappedItem.slotCategory.categoryPath : mappedItem?.category?.categoryPath"
                                  label="Product Category">
            </cb-display-value-new>
            <cb-text-area *ngIf="isDescriptiveOnly() && !isQuoted()"
                          name="description"
                          label="Description"
                          [disabled]="isLotSpecLocked$ | async"
                          [required]="true"
                          [maxlength]="1500"
                          [(ngModel)]="mappedItem.productLabel"
                          (change)="descriptionTextChanged()">
            </cb-text-area>
            <cb-display-value-new *ngIf="isQuoted()"
                                  [value]="mappedItem.productLabel"
                                  label="Description">
            </cb-display-value-new>
            <div class="start-center flex-row">
                <div class="flex" *ngIf="isNotDescriptive() && !isQuoted()">
                    <cb-product-autocomplete-scroller class="cb-margin-right flex"
                                                      label="Product"
                                                      name="product"
                                                      [maxlength]="1499"
                                                      [disabled]="isLotSpecLocked$ | async"
                                                      [(ngModel)]="selectedProduct"
                                                      [required]="true"
                                                      [requireMatch]="isActual()"
                                                      [matchProps]="['id']"
                                                      [hideCompositeItems]="false"
                                                      [orderByStandardProduct]="true"
                                                      [strictSearch]="strictSearch"
                                                      [categoryId]="mappedItem?.slotCategory?.id > 0 ? mappedItem.slotCategory.id : undefined"
                                                      (searchTextChanged)="productSearchTextChanged($event)"
                                                      (optionSelected)="productUpdate($event)">
                    </cb-product-autocomplete-scroller>
                    <cb-checkbox
                                 class="flex"
                                 label="Strict Search"
                                 name="applyStrictSearch"
                                 style="height: 30px"
                                 [disabled]="isLotSpecLocked$ | async"
                                 [(ngModel)]="strictSearch"></cb-checkbox>
                </div>
                <cb-product-input-find *ngIf="isNotDescriptive() && !isQuoted()"
                                       name="selectedProduct"
                                       (productSelected)="productUpdate($event)"
                                       [required]="true"
                                       [buttonOnly]="true"
                                       [maxlength]="1499"
                                       [activeOnly]="true"
                                       [disabled]="isLotSpecLocked$ | async"
                                       [hideCompositeItems]="false"
                                       [restrictedCategories]="mappedItem?.slotCategory?.id > 0 ? mappedItem.slotCategory.categoryTree : undefined"
                                       [hasRestrictedCategory]="mappedItem?.slotCategory?.id > 0">
                </cb-product-input-find>
            </div>
            <cb-text-area label="Text for Specification"
                          name="textForSpecification"
                          [(ngModel)]="mappedItem.clientFriendlyDescription"
                          [maxlength]="1500">
            </cb-text-area>
            <cb-input *ngIf="!isQuoted()"
                      type="number"
                      label="Quantity"
                      [disabled]="isLotSpecLocked$ | async"
                      [required]="true"
                      [(ngModel)]="mappedItem.quantity"
                      name="quantity"></cb-input>
            <cb-display-value-new *ngIf="isQuoted()"
                                  [value]="mappedItem.quantity"
                                  label="Quantity">
            </cb-display-value-new>
            <cb-currency *ngIf="showAmount() && !isQuoted()"
                         [label]="getAmountLabel()"
                         name="rateSnapshot"
                         [(ngModel)]="mappedItem.rateSnapshot"
                         [required]="false"
                         [disabled]="isLotSpecLocked$ | async"
                         [inclGst]="isProvisional()"></cb-currency>
            <cb-currency *ngIf="isQuoted()"
                         label="Quote Amount"
                         name="rateSnapshot"
                         [disabled]="isLotSpecLocked$ | async"
                         [ngModel]="mappedItem.rateSnapshot"
                         [readonly]="true">
            </cb-currency>
            <cb-select *ngIf="isNotDescriptive() && !isQuoted()"
                       label="Supplier"
                       name="supplier"
                       [disabled]="isLotSpecLocked$ | async"
                       [(ngModel)]="mappedItem.supplierId"
                       [options]="mappedItem.suppliers"></cb-select>
            <cb-display-value-new *ngIf="isQuoted()"
                                  [value]="mappedItem.supplierDisplay"
                                  label="Supplier">
            </cb-display-value-new>
            <cb-display-value-new *ngIf="isQuoted()"
                                  [value]="mappedItem.qRef"
                                  label="QRef">
            </cb-display-value-new>
        </mat-card>
        <mat-card class="cb-margin-top-2x cb-margin-bottom-2x flex-col flex">
            <h4 class="mat-h4 cb-margin-bottom-20">
                <b>Item Attributes</b>
            </h4>
            <div class="flex-row flex">
                <div class="cb-margin-right-3x image-block">
                    <div class="product-image">
                        <img [src]="getImageUrl()"
                             cbHandleInvalidImage
                             alt="Product Image"/>
                        <button class="view"
                                mat-mini-fab
                                (click)="viewImage()">
                            <mat-icon>fullscreen</mat-icon>
                        </button>
                        <input hidden
                               type="file"
                               id="imageUpload"
                               accept=".png, .jpg, .jpeg"
                               #fileInput
                               (change)="uploadImage($event,itemForm.form)"/>
                    </div>
                    <button *ngIf="canUploadImage()"
                            mat-raised-button
                            class="cb-margin-top"
                            (click)="fileInput.click()">
                        <mat-icon>file_upload</mat-icon>
                        <span>
                            &nbsp;Upload Image
                        </span>
                    </button>
                    <button *ngIf="canRemoveImage()"
                            class="cb-margin-top"
                            mat-raised-button
                            (click)="removeImage(itemForm.form)">
                        <mat-icon>delete</mat-icon>
                        &nbsp;Remove Image
                    </button>
                </div>
                <div class="flex-col flex-80">
                    <ng-container *ngIf="!isQuoted()">
                        <div *ngFor="let restriction of mappedItem?.restrictions"
                             class="restriction">
                            <cb-select [name]="'option'+ restriction.name"
                                       [label]="restriction.name"
                                       [(ngModel)]="restriction.selectedOptionId"
                                       noOptionsMessage="-"
                                       [readonly]="!isEditOrAdd()"
                                       [options]="restriction.options"></cb-select>
                            <cb-input type="text"
                                      [name]="'note'+restriction.name"
                                      label="Notes"
                                      [required]="false"
                                      [(ngModel)]="restriction.notes">
                            </cb-input>
                        </div>
                    </ng-container>
                    <div class="restriction"
                         *ngIf="mappedItem.manualColourEntryRequired">
                        <cb-input type="text"
                                  name="manualColour"
                                  label="Manual Colour Entry"
                                  [required]="false"
                                  [(ngModel)]="mappedItem.manualColourName">
                        </cb-input>
                        <cb-input type="text"
                                  name="manualColournotes"
                                  label="Notes"
                                  [required]="false"
                                  [(ngModel)]="mappedItem.manualColourNotes">
                        </cb-input>
                    </div>
                </div>
            </div>
        </mat-card>
        <cb-nullable-boolean label="Include in Flyer / Create Schedule"
                             name="showInClientSpecification"
                             [(ngModel)]="mappedItem.showInClientSpecification"
                             nullLabel="Not Set (Inherit from Product)">
        </cb-nullable-boolean>
        <cb-nullable-boolean label="Include in Colour Schedule"
                             name="showInColourYourDreams"
                             [(ngModel)]="mappedItem.showInColourYourDreams"
                             nullLabel="Not Set (Inherit from Product)"
                             #includeInColourSchedule>
        </cb-nullable-boolean>
        <cb-nullable-boolean label="Manual Colour Entry Required"
                             name="manualColourEntryRequired"
                             (change)="manualColourEntryRequiredChanged($event)"
                             [(ngModel)]="mappedItem.manualColourEntryRequired"
                             nullLabel="Not Set (Inherit from Product)">
        </cb-nullable-boolean>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="saveItem(itemForm)"
                    [clickLimit]="1"
                    [disabled]="itemForm.pristine || itemForm.invalid">Save</button>
            <button mat-raised-button
                    (cbClick)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
