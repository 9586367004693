
import { IAddressDto, IPurchaseOrderLineAttributeDto, ManualOrderReasonEnumId, SsrStateEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { findIndex, remove, sumBy } from 'lodash';
import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { IPurchaseOrderDto } from '../purchase-orders/interfaces/i.purchase-order.dto';
import { IPossibleLineItemDto, ISsrLineDto, ISsrLineItemDto } from './interfaces/i.ssr-line-item.dto';
import { ISsrDto } from './interfaces/i.ssr.dto';
import { ISsrMappedItem } from './interfaces/i.ssr.mapped';
import { ISsrsLogicService } from './interfaces/i.ssrs.logic.service';

export class SsrMappedItem
    extends BaseMappedItem<ISsrDto, ISsrMappedItem, ISsrsLogicService>
    implements ISsrMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public activityEnd: string;
    @DtoProp public activityName: string;
    @DtoProp public state: SsrStateEnumId;
    @DtoProp public businessAccountAddress: IAddressDto;
    @DtoProp public businessAccountLegalName: string;
    @DtoProp public businessAccountContactOrderRolesEmails: string[];
    @DtoProp public businessEntityAccountsPhone: string;
    @DtoProp public businessEntityAddress: IAddressDto;
    @DtoProp public businessEntityCreditorsEmail: string;
    @DtoProp public businessEntityEmail: string;
    @DtoProp public businessEntityLegalName: string;
    @DtoProp public businessEntityWebsite: string;
    @DtoProp public comments: string;
    @DtoProp public complianceItem: string;
    @DtoProp public complianceRecipient: string;
    @DtoProp public complianceStatement: string;
    @DtoProp public consentNumber: string;
    @DtoProp public constructionManagerEmail: string;
    @DtoProp public constructionManagerMobile: string;
    @DtoProp public constructionManagerName: string;
    @DtoProp public dpNumber: string;
    @DtoProp public duration: number;
    @DtoProp public ssrNumber: number;
    @DtoProp public endDate: string;
    @DtoProp public gst: number;
    @DtoProp public jobNumber: string;
    @DtoProp public lineItems: Array<ISsrLineDto>;
    @DtoProp public lotAddress: IAddressDto;
    @DtoProp public mainContactEmail: string;
    @DtoProp public mainContactMobile: string;
    @DtoProp public mainContactName: string;
    @DtoProp public orderDate: string;
    @DtoProp public orderNumber: string;
    @DtoProp public splitActivityKey: any;
    @DtoProp public splitActivityPercentage: number;
    @DtoProp public stageName: string;
    @DtoProp public startDate: string;
    @DtoProp public total: number;
    @DtoProp public totalWithGst: number;
    @DtoProp public valueRetainedPercentage: number;
    @DtoProp public attributes: IPurchaseOrderLineAttributeDto[];

    @Computed() public get hasComplianceStatement(): boolean {
        return this?.complianceStatement?.length > 0;
    }

    constructor(
        sourceData: ISsrDto,
        logicService: ISsrsLogicService
    ) {
        super(sourceData, logicService, SsrMappedItem);
    }

    public lineItemTotal(): number {
        return sumBy(this.lineItems, (item: ISsrLineDto) => (item.rate || 0) * item.quantity);
    }

    public removeLineItem(lineItemId: number): Observable<ISsrLineItemDto> {
        return this.$logicService
            .removeLineItem(this.id, lineItemId)
            .pipe(
                tap(result => {
                    remove(this.lineItems, { id: result.id });
                })
            );
    }

    public saveComments(): Observable<ISsrDto> {
        return this.$logicService
            .updateComments(this.id, this.comments)
            .pipe(
                tap(result => this.$updateThisAndOriginal(result))
            );
    }

    public convertToManualOrder(orderReasonId?: ManualOrderReasonEnumId, orderReason?: string): Observable<IPurchaseOrderDto> {
        return this.$logicService
            .convertToManualOrder(this.id, { orderReasonId, orderReason });
    }

    public updateLineItemQuantity(lineItemId: number, quantity: number): Observable<ISsrLineItemDto> {
        return this.$logicService
            .updateLineItemQuantity(lineItemId, quantity)
            .pipe(
                tap(this.handleUpdateLineItemQuantity)
            );
    }

    public getPossibleLineItems(): Observable<IPossibleLineItemDto[]> {
        return this.$logicService
            .getPossibleLineItems(this.id);
    }

    public addLineItems(items: IPossibleLineItemDto[]): Observable<ISsrLineItemDto[]> {
        return this.$logicService
            .addLineItems(this.id, items)
            .pipe(
                tap(() => {
                    // BUILD PROGRAMME TODO - the addLineItems endpoint should return the updated SsrDto
                    // which would remove the need for a separate reload
                    this.$reload().subOnce();
                })
            );
    }

    private readonly handleUpdateLineItemQuantity = (result: ISsrLineItemDto): void => {
        const index = findIndex(this.lineItems, { id: result.id });
        if (index < 0) {
            return;
        }
        this.lineItems[index].quantity = result.quantity;
        this.lineItems[index].subtotal = this.lineItems[index].quantity * this.lineItems[index].rate;
        if (isNullOrWhiteSpace(this.lineItems[index].code)) {
            this.lineItems[index].unallocatedQuantity = result.unallocatedQuantity;
        } else {
            this.lineItems.forEach(item => {
                if (item.code === this.lineItems[index].code) {
                    item.unallocatedQuantity = result.unallocatedQuantity;
                }
            });
        }
    };
}
