import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IChangeOptionLineLogicService } from '@app/logic/change-option-line/interfaces/i.change-option-line.logic.service';
import { IChangeOptionLineMappedItem } from '@app/logic/change-option-line/interfaces/i.change-option-line.mapped';
import { IChangeOptionMappedItem } from '@app/logic/change-option/interfaces/i.change-option.mapped';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { LotSpecLogicService } from '@app/logic/lot-spec/lot-spec.logic.service';
import { ProductLogicService } from '@app/logic/products';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import {
    ChangeDirectionEnumId,
    CHANGE_DIRECTION_ENUM,
    COST_TYPE_ENUM,
    IChangeOptionLineDto,
    IIdAndNameDto,
    ILotSpecItemDto,
    ISpecGroupDto, IProductOfferingDto
} from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: IChangeOptionLineMappedItem;
    houseAreas?: ISpecGroupDto[];
    lotId: number;
    formMode: FormMode;
    changeRecordMappedItem: IChangeRecordMappedItem;
    changeOptionMappedItem: IChangeOptionMappedItem;
}


@Component({
    selector: 'cb-non-sim-change-option-line-dialog',
    templateUrl: './non-sim-change-option-line-dialog.component.html',
    styleUrls: ['./non-sim-change-option-line-dialog.component.scss']
})
export class NonSimChangeOptionLineDialogComponent extends BaseDialogFormViewDirective<IChangeOptionLineDto, IChangeOptionLineMappedItem, IChangeOptionLineLogicService> {

    public static readonly MIN_WIDTH = '40%';

    public mappedItem: IChangeOptionLineMappedItem;
    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public CHANGE_DIRECTION_ENUM = CHANGE_DIRECTION_ENUM;
    public changeOptionLineCostTypes = COST_TYPE_ENUM.toSelectList().filter(x => x.id !== COST_TYPE_ENUM.None && x.id !== COST_TYPE_ENUM.Quote);
    public productSearchText: string;
    public readonly houseAreas: ISpecGroupDto[];
    public changeRecordMappedItem: IChangeRecordMappedItem;
    public changeOptionMappedItem: IChangeOptionMappedItem;

    public existingLotSpecItems: ILotSpecItemDto[] = [];
    public filteredLotSpecItems: ILotSpecItemDto[] = [];

    public selectedLotSpec: ILotSpecItemDto;

    constructor(
        public readonly dialogRef: MatDialogRef<NonSimChangeOptionLineDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(LotSpecLogicService) public readonly lotSpecLogicService: LotSpecLogicService,
        @Inject(ProductLogicService) public readonly productLogicService: ProductLogicService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = this.data.mappedItem;
        this.mappedItem.lotId = this.data.lotId;
        this.changeRecordMappedItem = this.data.changeRecordMappedItem;
        this.changeOptionMappedItem = this.data.changeOptionMappedItem;
        this.houseAreas = this.data.houseAreas;
        this.formMode = this.data.formMode;
    }

    public ngOnInit(): void {
        /** When editing a line item with a product that has been
           manually typed, this is to ensure that the product search box gets populated
           with the manually entered product text and also remains set upon saving **/
        if (!this.isProductSelected() && this.mappedItem.itemDetails != null) {
            this.mappedItem.selectedProduct = {
                id: 0,
                name: this.mappedItem.itemDetails
            };
            this.productSearchText = this.mappedItem.itemDetails;
        }

        this.lotSpecLogicService.getLotSpecItems(this.mappedItem.lotId).subOnce((result) => {
            result = this.removeExistingCreditedSpecItemOptionsLinesFromAllSpecItems(result);
            this.existingLotSpecItems = result;
            this.filteredLotSpecItems = result;
            this.populateSelectedLotSpec();
        });
    }

    // Only allow users to select items to credit that aren't already being credited.
    private removeExistingCreditedSpecItemOptionsLinesFromAllSpecItems(allLotSpecItems: ILotSpecItemDto[]): ILotSpecItemDto[] {
        const existingCreditedSpecItemIds = this.changeOptionMappedItem.changeOptionLines
            .filter(x => x.changeDirection === ChangeDirectionEnumId.Credit)
            .map(x => x.lotSpecItemId);
        return allLotSpecItems.filter(item => {
            return existingCreditedSpecItemIds.indexOf(item.id) === -1;
        });
    }

    private populateSelectedLotSpec(): void {
        if (this.mappedItem.lotSpecItemId > 0) {
            this.selectedLotSpec = this.existingLotSpecItems.find(x => x.id === this.mappedItem.lotSpecItemId);
        }
    }

    public changeDirectionChanged(): void {
        if (!this.canBeQuoted()) {
            this.mappedItem.quoted = false;
        }
        this.resetLockedQuantity();
        if (this.mappedItem.changeDirection === CHANGE_DIRECTION_ENUM.Credit) {
            this.mappedItem.specGroupId = undefined;
        }
    }

    public quantityIsLocked(): boolean {
        return this.mappedItem.lotSpecItemHasQuoteLines
            && this.mappedItem.changeDirection === CHANGE_DIRECTION_ENUM.Credit
            || this.isDescriptive()
            || this.isQuoted();
    }

    public resetLockedQuantity(): void {
        if (this.quantityIsLocked()) {
            this.mappedItem.quantity = (this.mappedItem.quantity || 0);
        }
    }


    public canBeQuoted(): boolean {
        return this.mappedItem.changeDirection === CHANGE_DIRECTION_ENUM.Add
            && this.mappedItem.costType !== COST_TYPE_ENUM.DescriptiveOnly;
    }

    public isQuoted(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Quote;
    }

    public isActual(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Actual;
    }

    public isDescriptiveOnly(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.DescriptiveOnly;
    }

    public isEstimated(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Estimate;
    }

    public isProvisional(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Provisional;
    }

    public isAddItem(): boolean {
        return this.mappedItem?.changeDirection === CHANGE_DIRECTION_ENUM.Add;
    }

    public isCreditItem(): boolean {
        return this.mappedItem?.changeDirection === CHANGE_DIRECTION_ENUM.Credit;
    }

    public isNotDescriptive(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Actual ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Provisional ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Estimate ||
            this.mappedItem?.costType === COST_TYPE_ENUM.NoCharge ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Quote ||
            this.mappedItem?.costType === COST_TYPE_ENUM.OwnersCare;
    }

    public isDescriptive(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.DescriptiveOnly;
    }

    public productSearchTextChanged(str: string): void {
        if (str != null && str !== undefined && str.length > 0) {
            this.productSearchText = str;
        }
    }

    public specGroupSelected(specGroupId: number): void {
        if (specGroupId > 0) {
            this.mappedItem.specGroupName = this.houseAreas.find(x => x.id === specGroupId)?.label;
        }
    }


    public productUpdate = (product?: IIdAndNameDto | null): void => {
        if (product != null && product !== undefined) {

            this.productSearchText = product.name;

            if (this.isProductSelected()) {
                this.mappedItem.itemDetails = null;

                // Get Rate and populate
                this.getRateAndPopulateRateAmount();

            }

        } else if (!this.mappedItem.selectedProduct) {
            if (!this.isDescriptiveOnly()) {
                this.productSearchText = null;
            }
        }
    };

    public getRateAndPopulateRateAmount = (): void => {
        this.productLogicService.getRateForLot(this.mappedItem?.selectedProduct?.id, this.mappedItem?.lotId)
            .subOnce(rateDto => {
                this.mappedItem.rate = rateDto?.value || 0;

                // Recalculate Amount
                this.calculateCostAmount();

            });
    };

    public clearNonSelectedProduct(): void {
        if (this.isActual() && !this.isProductSelected()) {
            this.mappedItem.itemDetails = null;
            this.mappedItem.selectedProduct = {} as IIdAndNameDto;
        }
    }

    private isProductSelected(): boolean {
        return (this.mappedItem.selectedProduct as IIdAndNameDto)?.id > 0;
    }

    public productSelected = (product?: IProductOfferingDto | null): void => {

        let selectedProductDto = {} as IIdAndNameDto;

        if (product?.id > 0) {
            selectedProductDto = {
                id: product.id,
                name: product.name
            } as IIdAndNameDto;

            this.mappedItem.selectedProduct = selectedProductDto;

            if (this.isProductSelected()) {
                this.mappedItem.itemDetails = null;

                this.getRateAndPopulateRateAmount();
            }

        }

    };


    public quantityChanged(): void {
        this.calculateCostAmount();
    }

    public calculateCostAmount(): void {
        if (!this.isQuoted()) {
            this.mappedItem.costAmount = this.mappedItem.quantity * this.mappedItem.rate;
        }
    }

    public displayFn(item: ILotSpecItemDto): string {
        if (item?.hasQuoteLines) {
            return item.quoteLineDescription;
        }
        return item && item.productDisplay;
    }

    public lotSpecUpdate = (): void => {

        if (this.selectedLotSpec && this.mappedItem.lotSpecItemId !== this.selectedLotSpec.id) {

            // Update selected LotSpec
            this.mappedItem.lotSpecItemId = this.selectedLotSpec?.id;
            this.mappedItem.specGroupId = this.selectedLotSpec?.specGroupId;
            this.mappedItem.specGroupName = this.selectedLotSpec?.specGroupName;

            this.mappedItem.selectedProduct = undefined;

            if (this.selectedLotSpec.quoteLineDescription && this.selectedLotSpec.quoteLineDescription.length > 0) {
                this.mappedItem.itemDetails = this.selectedLotSpec.quoteLineDescription;
                this.productSearchText = this.selectedLotSpec.quoteLineDescription;
            } else if (this.selectedLotSpec.productDisplay) {
                this.mappedItem.itemDetails = this.selectedLotSpec.productDisplay;
                this.productSearchText = this.selectedLotSpec.productDisplay;
            }

            if (this.selectedLotSpec.costType !== undefined) {
                this.mappedItem.costType = this.selectedLotSpec.costType;
            }

            if (this.selectedLotSpec.quantity !== undefined) {
                this.mappedItem.quantity = this.selectedLotSpec.quantity;
            }

            if (this.selectedLotSpec.quotedLinesAllocatedAmount !== undefined && this.selectedLotSpec.costType === COST_TYPE_ENUM.Quote) {
                this.mappedItem.rate = this.selectedLotSpec.quotedLinesAllocatedAmount;
                this.mappedItem.costAmount = this.mappedItem.quantity * this.mappedItem.rate;
            } else if (this.selectedLotSpec.rate !== undefined) {
                this.mappedItem.rate = this.selectedLotSpec.rate;
            }
        } else if (!this.selectedLotSpec) {
            if (this.mappedItem) {
                this.mappedItem.lotSpecItemId = undefined;
            }
        }
    };


    public lotSpecificationTextChanged(value: string): void {
        this.filteredLotSpecItems = this.existingLotSpecItems
            .filter(option => option.hasQuoteLines ?
                option.quoteLineDescription.toLowerCase().includes(value.toLowerCase()) :
                option.productDisplay.toLowerCase().includes(value.toLowerCase()));
    }


    public addOrUpdateItem(): void {

        this.calculateCostAmount();

        if (!this.isProductSelected() && !this.isQuoted()) {
            this.mappedItem.selectedProduct = {} as IIdAndNameDto;
            this.mappedItem.itemDetails = this.productSearchText;
        }

        this.dialogRef.close(this.mappedItem.$getMappedDtoItem());
    }

}
