import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { BusinessAccountLogicService, BusinessAccountMappedItem, IBusinessAccountDto, IBusinessAccountSearchDto, IBusinessAccountSearchParams } from '@app/logic/business-accounts';
import {
    BusinessAccountSearchTypeEnumId, BusinessAccountStatusEnumId,
    BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM, BUSINESS_ACCOUNT_STATUS_ENUM, IStringIdAndLabelDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { find, orderBy, filter } from 'lodash';
import { ILocationDto, LocationLogicService } from '@app/logic/location';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { CurrentUserService } from '@app/core/authentication/current.user';

@Component({
    selector: 'cb-/business-accounts',
    templateUrl: './business-accounts.component.html',
    styleUrls: ['./business-accounts.component.scss']
})
export class BusinessAccountsComponent extends BaseSimpleListViewDirective<IBusinessAccountDto, BusinessAccountLogicService> implements AfterViewInit {

    public tradeTypes: any[];
    public accountTypes: IEnumLookup<BusinessAccountSearchTypeEnumId>[];
    public accountStatuses: IEnumLookup<BusinessAccountStatusEnumId>[];
    public locations: ILocationDto[];
    public filterChips: IStringIdAndLabelDto[];
    public accounts: IBusinessAccountDto[] = [];
    public refreshData$ = new Subject();
    private readonly subscription$ = new Subscription();
    public readonly queryUpdate$ = new Subject();
    public fetchInProgress = false;
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public get userCacheItem(): UserCacheItem<IBusinessAccountSearchParams> {
        return this.userCacheService.businessAccountsSearch;
    }

    public searchEnabled$ = new BehaviorSubject(false);
    public get searchEnabled(): boolean {
        return this.searchEnabled$.value;
    }

    public currentPage: number;

    constructor(
        private readonly userCacheService: UserCacheService,
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly businessAccountLogicService: BusinessAccountLogicService,
        protected readonly navigationService: NavigationService,
        protected readonly lookupService: LookupService,
        protected readonly locationLogicService: LocationLogicService,
        private readonly currentUser: CurrentUserService,
        private readonly cdr: ChangeDetectorRef
    ) {
        super(
            cbDialog,
            businessAccountLogicService,
            null,
            null,
            BusinessAccountMappedItem,
            permissionsPermissions,
            false
        );

        this.lookupService.getLookup('tradeTypes').then(result => {
            this.tradeTypes = result;
            this.tradeTypes = orderBy(this.tradeTypes, 'label');
            this.tradeTypes.unshift({ id: 0, label: 'No Filter' });
        });

        this.locationLogicService.getFlatTree(true).subOnce(result => this.locations = result);

        this.accountTypes = BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM.toLookup({
            transform: array => {
                let accountTypes = filter(array, item => item.id > 0);
                accountTypes = orderBy(accountTypes, 'label');
                accountTypes.unshift({ id: 0, label: 'No Filter' } as IEnumLookup<BusinessAccountSearchTypeEnumId>);
                return accountTypes;
            }
        });

        this.accountStatuses = BUSINESS_ACCOUNT_STATUS_ENUM.toLookup({
            transform: array => {
                let accountStatuses = filter(array, item => item.id > 0);
                accountStatuses = orderBy(accountStatuses, 'label');
                accountStatuses.unshift({ id: 0, label: 'No Filter' } as IEnumLookup<BusinessAccountStatusEnumId>);
                return accountStatuses;
            }
        });
    };

    public ngAfterViewInit(): void {
        this.initSearchFiltersCache();
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    public onEnter($event: any, searchQuery: string): void {
        $event.stopPropagation();
        $event.preventDefault();
        this.userCacheItem.data.query = searchQuery;
    }

    public fetchResults(): Observable<ISearchResult<IBusinessAccountSearchDto>> {
        if (!this.searchEnabled) {
            return;
        }

        const data = this.userCacheItem.copyData();
        data.currentpage = this.currentPage;

        return this.businessAccountLogicService.$getSearchList(data);
    }

    public initSearchFiltersCache(): void {
        Promise.all([
            this.userCacheItem.init(),
            this.currentUser.$promise,
        ]).then(() => {
            this.searchEnabled$.next(true);
            this.searchFilterChanged();

            this.subscription$.add(
                this.userCacheItem.updated$.subscribe({
                    next: this.searchFilterChanged
                })
            );
        });
    }

    public searchFilterChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.updateFilterChips();

        this.currentPage = 1;
        this.queryUpdate$.next(null);
        this.cdr.detectChanges();
    };

    public onCreateNewAccountClicked(): void {
        this.navigationService.navigate(['/business-accounts/create']);
    }

    public onRequestNewAccountClicked(): void {
        this.navigationService.navigate(['/business-accounts/request']);
    }

    public onViewAccountClicked(account: IBusinessAccountDto): void {
        this.navigationService.navigate([`/business-accounts/edit/${account.id}/details`]);
    }

    public onExportAccountsClicked(): void {
        this.businessAccountLogicService.export().subOnce();
    }

    public onLocationChanged(location: any): void {
        this.userCacheItem.data.reg = location.region;
        this.userCacheItem.data.dis = location.district;
        this.userCacheItem.data.sub = location.area;
        this.updateFilterChips();
    }

    public onChipRemoved(filterId: string): void {
        if (this.userCacheItem.data && filterId) {
            this.userCacheItem.data[filterId] = undefined;
        }
    }

    private updateFilterChips(): void {
        this.filterChips = [];

        if (this.userCacheItem.data.query) {
            this.filterChips.push({ id: 'query', label: `Keywords: ${this.userCacheItem.data.query}` });
        }

        if (this.userCacheItem.data.t) {
            const match = find(this.accountTypes, { id: this.userCacheItem.data.t });
            this.filterChips.push({ id: 't', label: `Account Type: ${match?.label}` });
        }

        if (this.userCacheItem.data.tt) {
            const match = find(this.tradeTypes, { id: this.userCacheItem.data.tt });
            this.filterChips.push({ id: 'tt', label: `Trade Type: ${match?.label}` });
        }

        if (this.userCacheItem.data.s) {
            const match = find(this.accountStatuses, { id: this.userCacheItem.data.s });
            this.filterChips.push({ id: 's', label: `Account Status: ${match?.label}` });
        }

        if (this.userCacheItem.data.reg) {
            const match = find(this.locations, { id: this.userCacheItem.data.reg });
            this.filterChips.push({ id: 'reg', label: `Region: ${match?.label}` });
        }

        if (this.userCacheItem.data.dis) {
            const match = find(this.locations, { id: this.userCacheItem.data.dis });
            this.filterChips.push({ id: 'dis', label: `District: ${match?.label}` });
        }

        if (this.userCacheItem.data.sub) {
            const match = find(this.locations, { id: this.userCacheItem.data.sub });
            this.filterChips.push({ id: 'sub', label: `Area: ${match?.label}` });
        }

        if (this.userCacheItem.data.st) {
            this.filterChips.push({ id: 'st', label: 'Strict Search' });
        }
    }
}
