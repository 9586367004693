<form class="flex-row"
      #editContactForm="ngForm">
    <mat-tab-group class="flex"
                   #tabGroup
                   [cbTabRoute]="CONTACT_FULL_ROUTE"
                   defaultTabId="summary">
        <mat-tab label="Contact Details"
                 cbTabRouteId="summary">
            <ng-template matTabContent>
                <div *ngIf="!isEdit">
                    <cb-contact-details *cbWaitFor="contact"
                                        [contact]="contact">
                    </cb-contact-details>
                </div>
                <div *ngIf="isEdit">
                    <cb-contact-edit *cbWaitFor="contact"
                                     [(contact)]="editContact"
                                     (isEmailDuplicate)="isEmailDuplicate = $event">
                    </cb-contact-edit>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Accounts"
                 cbTabRouteId="accounts">
            <ng-template matTabContent>
                <cb-account-list *cbWaitFor="contact"
                                 [contactId]="contact.id"></cb-account-list>
            </ng-template>
        </mat-tab>
        <mat-tab label="Leads"
                 cbTabRouteId="leads">
            <ng-template matTabContent>
                <cb-contact-leads *cbWaitFor="contact"
                                  [contact]="contact">
                </cb-contact-leads>
            </ng-template>
        </mat-tab>
        <mat-tab label="Notes"
                 cbTabRouteId="notes">
            <ng-template matTabContent>
                <cb-notes *cbWaitFor="contact"
                              [entity]="contact"
                              [edit]="true"
                              [permissions]="permissions.getNotePermissions()">
                </cb-notes>
            </ng-template>
        </mat-tab>
        <mat-tab label="My Classic"
                 cbTabRouteId="myclassic">
            <ng-template matTabContent>
                <cb-my-classic *cbWaitFor="contact"
                               [contact]="contact">
                </cb-my-classic>
            </ng-template>
        </mat-tab>
        <mat-tab label="History"
                 cbTabRouteId="history">
            <ng-template matTabContent>
                <cb-history-list *cbWaitFor="contact"
                                 [entityId]="contact?.id"
                                 [entity]="HISTORY_SEARCH_ENTITY.Contact"></cb-history-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <cb-side-bar *cbWaitFor="contact"
                 class="cb-margin flex-20">
        <div class="flex-col flex">
            <div class="start-center flex-row">
                <mat-icon svgIcon="account"></mat-icon>
                <h1 class="mat-title cb-margin-bottom-nil"> {{contact.firstName}} {{contact.lastName}} </h1>
            </div>
            <mat-divider [inset]="true"> </mat-divider>
            <cb-display-value-new class="cb-padding-top-2x"
                                  label="Status"
                                  [value]="CONTACT_STATUS_ENUM[contact.status]"></cb-display-value-new>
            <cb-display-value-new label="Address"
                                  [value]="contact.physicalAddress?.street"
                                  style="margin-bottom: 0px;"></cb-display-value-new>
            <p>
                {{contact.physicalAddress?.suburb}}
            </p>
            <p>
                {{contact.physicalAddress?.city }} {{contact.physicalAddress?.postCode }}
            </p>
            <p style="margin-bottom:12px;">
                {{contact.physicalAddress?.regionName}}
            </p>
            <cb-display-value-new label="Email"
                                  [value]="contact.email"></cb-display-value-new>
            <cb-display-value-new label="Mobile Phone"
                                  [value]="contact.phoneMobile"></cb-display-value-new>
            <cb-display-value-new label="Created"
                                  [value]="getCreatedDisplayValue()"></cb-display-value-new>
            <cb-display-value-new label="Updated"
                                  [value]="getUpdatedDisplayValue()"></cb-display-value-new>
            <div *ngIf="tabGroup.selectedIndex === 0"
                 class="cb-margin-top-3x">
                <button *ngIf="!isEdit"
                        mat-raised-button
                        color="primary"
                        class="cb-margin-bottom-2x cb-margin-right-2x"
                        (click)="OpenEditForm()">
                    Edit Contact
                </button>
                <button *ngIf="isEdit"
                        mat-raised-button
                        color="primary"
                        class="cb-margin-bottom-2x cb-margin-right-2x"
                        [disabled]="!editContactForm.dirty || !editContactForm.valid || isEmailDuplicate"
                        (click)="saveContact()">
                    Save Contact
                </button>
                <button *ngIf="isEdit"
                        mat-raised-button
                        class="cb-margin-bottom-2x"
                        (click)="cancelEdit()">
                    Cancel
                </button>
            </div>
        </div>
    </cb-side-bar>
</form>
