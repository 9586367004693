import { Component } from '@angular/core';
import { BUSINESS_ACCOUNT_STATUS_ENUM, ITradeTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IProductDto, ProductLogicService } from '@app/logic/products';
import { IBusinessAccountDto } from '@app/logic/business-accounts';
import { DialogLoadingSpinnerComponent } from 'cb-hub-lib';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'cb-product-usage-export',
    templateUrl: './product-usage-export.component.html',
    styleUrls: ['./product-usage-export.component.scss']
})
export class ProductUsageExportComponent {

    public product: IProductDto;
    public businessAccount: IBusinessAccountDto;

    public createdDateFrom: Date;
    public createdDateTo: Date;

    public activityStartDateFrom: Date;
    public activityEndDateTo: Date;

    public jobNo: string;
    public selectedTradeTypeDto: ITradeTypeDto;


    public productUsageBusinessAccountSearchParams = {
        s: [
            BUSINESS_ACCOUNT_STATUS_ENUM.Active,
            BUSINESS_ACCOUNT_STATUS_ENUM.Overridden,
            BUSINESS_ACCOUNT_STATUS_ENUM.OnHold,
            BUSINESS_ACCOUNT_STATUS_ENUM.Closed
        ]
    };
    constructor(
        protected readonly productsLogicService: ProductLogicService,
        private readonly dialog: MatDialog,) { }

    public productSelected(value: IProductDto | void): void {
        if (value) {
            this.product = value;
        }
    }

    public exportProductUsage(): void {
        const message = 'Exporting Product Usage...';
        const loadingDialog = this.dialog.open(
            DialogLoadingSpinnerComponent,
            {
                data: { message },
                width: '400px',
                height: '100px',
                panelClass: 'cb-dialog-container'
            });

        const params = {
            ...(this.product && { id: this.product.id }),
            ...(this.businessAccount && { businessAccountId: this.businessAccount.id }),
            ...(this.createdDateFrom && { createdDateFrom: this.createdDateFrom }),
            ...(this.createdDateTo && { createdDateTo: this.createdDateTo }),
            ...(this.activityStartDateFrom && { activityStartDateFrom: this.activityStartDateFrom }),
            ...(this.activityEndDateTo && { activityEndDateTo: this.activityEndDateTo }),
            ...(this.jobNo && { jobNo: this.jobNo }),
            ...(this.selectedTradeTypeDto && { tradeTypeId: this.selectedTradeTypeDto.id }),
        };

        this.productsLogicService.exportProductUsage(params).subOnce(_ => {
            loadingDialog.close();
        });
    }
}
