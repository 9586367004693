import { BaseMappedItem } from '../base/base-mapped-item';
import {
    IBusinessAccountLogicService,
    IDocTypeBizAccountTypesLogicService,
    IDocTypeSupplyTypesLogicService,
    IDocTypeTradeTypesLogicService
} from './interfaces/i.business-account.logic.service';
import { IBusinessAccountMappedItem, IDocTypeBizAccountTypeMappedItem, IDocTypeSupplyTypeMappedItem, IDocTypeTradeTypeMappedItem } from './interfaces/i.business-account.mapped';
import { IBusinessAccountDto, IDocTypeBusinessAccountTypeDto, IDocTypeSupplyTypeDto, IDocTypeTradeTypeDto, ParentDocsMaintainance } from './interfaces/i.business-account.dto';
import { IDocumentTypeDto } from '../document-types';
import { DtoProp } from '../base/dto-prop.decorator';
import { BusinessAccountTypeEnumId, BUSINESS_ACCOUNT_STATUS_ENUM, ISupplyTypeDto, ITradeTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map } from 'lodash';

export class BusinessAccountMappedItem
    extends BaseMappedItem<IBusinessAccountDto, IBusinessAccountMappedItem, IBusinessAccountLogicService>
    implements IBusinessAccountMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public tradingName: string;
    @DtoProp public isActive: boolean;
    @DtoProp public bundleItems: any[];
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public isSupplier: boolean;
    @DtoProp public isInstaller: boolean;
    @DtoProp public email: string;
    @DtoProp public legalName: string;
    @DtoProp public phoneNumber: string;
    @DtoProp public status: number;
    @DtoProp public regions: Array<number>;
    @DtoProp public accountCode: string;
    @DtoProp public locations: any[];
    @DtoProp public businessAccountTradeTypes: any[];
    @DtoProp public businessAccountSupplyTypes: any[];
    @DtoProp public bankName: string;
    @DtoProp public bankAccountNumber: string;
    @DtoProp public abmCode: string;
    @DtoProp public reason: string;
    @DtoProp public sevenDayPaymentRequest: any;
    @DtoProp public canRequest7Days: boolean;
    @DtoProp public availableHolds: any[];
    @DtoProp public holds: any[];
    @DtoProp public canCloseAccount: boolean;
    @DtoProp public allRequiredDocumentsUploaded: boolean;
    @DtoProp public anyDocumentRequiresReview: boolean;
    @DtoProp public approvalOverrideRequest: any;
    @DtoProp public approvedByHealthAndSafetyDate: string;
    @DtoProp public approvedByHealthAndSafetyUser: string;
    @DtoProp public approvedByProcurementDate: string;
    @DtoProp public approvedByProcurementUser: string;
    @DtoProp public businessName: string;
    @DtoProp public canHealthSafetyApprove: boolean;
    @DtoProp public canProcurementApprove: boolean;
    @DtoProp public canRequestApprovalOverride: boolean;
    @DtoProp public canSubmitForApproval: boolean;
    @DtoProp public contractsStatus: number;
    @DtoProp public creditorsContactExistsOnAccount: boolean;
    @DtoProp public declinedByTag: string;
    @DtoProp public declinedByUser: string;
    @DtoProp public declinedDate: string;
    @DtoProp public declinedReason: string;
    @DtoProp public defaultLocationId: number;
    @DtoProp public gstNumber: string;
    @DtoProp public healthAndSafetyStatus: number;
    @DtoProp public healthandSafetyRequestDeclinedByUser: string;
    @DtoProp public healthandSafetyRequestDeclinedDate: string;
    @DtoProp public healthandSafetyRequestDeclinedReason: string;
    @DtoProp public isParentAccount: boolean;
    @DtoProp public isRatesOnly: boolean;
    @DtoProp public ordersContactExistsOnAccount: boolean;
    @DtoProp public parentAccount: any;
    @DtoProp public parentAccountId: number;
    @DtoProp public paymentFrequency: number;
    @DtoProp public physicalAddress: any;
    @DtoProp public postalAddress: any;
    @DtoProp public requestApprovedByUser: string;
    @DtoProp public requestApprovedDate: string;
    @DtoProp public requestCancelledByUser: string;
    @DtoProp public requestCancelledDate: string;
    @DtoProp public requestDeclinedByUser: string;
    @DtoProp public requestDeclinedDate: string;
    @DtoProp public requestDeclinedReason: string;
    @DtoProp public requestedBy: string;
    @DtoProp public requestedById: string;
    @DtoProp public requestedDate: string;
    @DtoProp public selectedRegions: any;
    @DtoProp public shouldMaintainRequiredDocumentsOnChildAccounts: boolean;
    @DtoProp public shouldMaintainRequiredDocumentsOnParentAccount: boolean;
    @DtoProp public tradeTypes: number[];
    @DtoProp public supplyTypes: number[];
    @DtoProp public hasGstNumber: boolean;
    @DtoProp public parentDocsMaintainedOn: number;

    constructor(
        sourceData: IBusinessAccountDto,
        logicService: IBusinessAccountLogicService,
    ) {
        super(sourceData, logicService, BusinessAccountMappedItem);
    }

    public get noteEntityId(): number {
        return this.id;
    }

    public get noteEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    protected $preSave(toSave: IBusinessAccountDto): void {
        toSave.bundleItems = toSave.bundleItems ? toSave.bundleItems.filter(x => x.name) : null;
        toSave.locations = toSave.locations ? map(toSave.locations, item => item.id) : null;
        toSave.postalAddress = toSave.postalAddress?.street ? toSave.postalAddress : undefined;
        if (toSave.isParentAccount) {
            switch (toSave.parentDocsMaintainedOn) {
                case ParentDocsMaintainance.ParentAccountOnly:
                    toSave.shouldMaintainRequiredDocumentsOnChildAccounts = false;
                    toSave.shouldMaintainRequiredDocumentsOnParentAccount = true;
                    break;
                case ParentDocsMaintainance.ChildAccountsOnly:
                    toSave.shouldMaintainRequiredDocumentsOnChildAccounts = true;
                    toSave.shouldMaintainRequiredDocumentsOnParentAccount = false;
                    break;
                default:
                    toSave.shouldMaintainRequiredDocumentsOnChildAccounts = true;
                    toSave.shouldMaintainRequiredDocumentsOnParentAccount = true;
                    break;
            }
        }
    }

    public canEdit(): boolean {
        return this.id && !this.isClosedOrDeclined() && !this.isPending();
    }

    public canEditFinanceInformation(): boolean {
        return this.id && !this.isClosedOrDeclined() && !this.isPending();
    }

    public canRequestSevenDays(): boolean {
        return this.id && !this.isClosedOrDeclined() && !this.sevenDayPaymentRequest && this.canRequest7Days;
    }

    public canAddAccountHold(): boolean {
        return this.id && !this.isClosedOrDeclined()
            && [BUSINESS_ACCOUNT_STATUS_ENUM.Active, BUSINESS_ACCOUNT_STATUS_ENUM.Overridden, BUSINESS_ACCOUNT_STATUS_ENUM.OnHold].indexOf(this.status) > -1
            && (!this.holds?.length || this.holds?.length < 4);
    }

    public isClosedOrDeclined(): boolean {
        return this.isClosed() || this.isDeclined();
    }

    public canClose(): boolean {
        return !this.isClosedOrDeclined() && this.canCloseAccount;
    }

    public isNewRequest(): boolean {
        return this.status === BUSINESS_ACCOUNT_STATUS_ENUM.NewRequest;
    }

    public isPending(): boolean {
        return this.status === BUSINESS_ACCOUNT_STATUS_ENUM.PendingRequestApproval;
    }

    public canBeSupplier(): boolean {
        if (this.parentAccount && !this.parentAccount.shouldMaintainRequiredDocumentsOnChildAccounts) {
            return this.parentAccount.isSupplier;
        }
        return true;
    }

    public canBeInstaller(): boolean {
        if (this.parentAccount && !this.parentAccount.shouldMaintainRequiredDocumentsOnChildAccounts) {
            return this.parentAccount.isInstaller;
        }
        return true;
    }

    private isClosed(): boolean {
        return this.status === BUSINESS_ACCOUNT_STATUS_ENUM.Closed || this.status === BUSINESS_ACCOUNT_STATUS_ENUM.RequestCancelled;
    }

    private isDeclined(): boolean {
        return this.status === BUSINESS_ACCOUNT_STATUS_ENUM.Declined || this.status === BUSINESS_ACCOUNT_STATUS_ENUM.RequestDeclined;
    }
}

export class DocTypeBizAccountTypeMappedItem
    extends BaseMappedItem<IDocTypeBusinessAccountTypeDto, IDocTypeBizAccountTypeMappedItem, IDocTypeBizAccountTypesLogicService>
    implements IDocTypeBizAccountTypeMappedItem {
    @DtoProp public readonly id: BusinessAccountTypeEnumId;
    @DtoProp public documentTypeIds: Array<number>;
    @DtoProp public documentTypes: Array<IDocumentTypeDto>;

    constructor(
        sourceData: IDocTypeBusinessAccountTypeDto,
        logicService: IDocTypeBizAccountTypesLogicService
    ) {
        super(sourceData, logicService, DocTypeBizAccountTypeMappedItem);
    }
}

export class DocTypeTradeTypeMappedItem
    extends BaseMappedItem<IDocTypeTradeTypeDto, IDocTypeTradeTypeMappedItem, IDocTypeTradeTypesLogicService>
    implements IDocTypeTradeTypeMappedItem {
    @DtoProp public id: number;
    @DtoProp public tradeType: ITradeTypeDto;
    @DtoProp public documentTypeIds: Array<number>;
    @DtoProp public documentTypes: Array<IDocumentTypeDto>;

    constructor(
        sourceData: IDocTypeTradeTypeDto,
        logicService: IDocTypeTradeTypesLogicService
    ) {
        super(sourceData, logicService, DocTypeTradeTypeMappedItem);
    }
}

export class DocTypeSupplyTypeMappedItem
    extends BaseMappedItem<IDocTypeSupplyTypeDto, IDocTypeSupplyTypeMappedItem, IDocTypeSupplyTypesLogicService>
    implements IDocTypeSupplyTypeMappedItem {
    @DtoProp public id: number;
    @DtoProp public supplyType: ISupplyTypeDto;
    @DtoProp public documentTypeIds: Array<number>;
    @DtoProp public documentTypes: Array<IDocumentTypeDto>;

    constructor(
        sourceData: IDocTypeSupplyTypeDto,
        logicService: IDocTypeSupplyTypesLogicService
    ) {
        super(sourceData, logicService, DocTypeSupplyTypeMappedItem);
    }
}
