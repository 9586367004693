import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NonWorkingDayLogicService, INonWorkingDayDto } from '@app/logic/non-working-day';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { DatePickerType } from '@app/shared/types/date-picker.type';
import * as moment from 'moment';
import {DateUtil} from '@app/shared/utils/date.util';



const DEBOUNCE_DURATION = 500; // milliseconds

@Component({
    selector: 'cb-date-duration-calculator',
    templateUrl: './date-duration-calculator.component.html',
    styleUrls: ['./date-duration-calculator.component.scss'],
    viewProviders: [
        provideParentForm()
    ],
})
export class DateDurationCalculatorComponent implements OnInit {
    public static MinimumDuration = 1;
    public static MaximumDuration = 999;

    @Input() public pickerType: DatePickerType | null;
    @Input() public name: string;
    @Output() public change = new EventEmitter();
    @Output() public updated = new EventEmitter();
    @Input() public readonly lotId: number;
    @Input() public readonly required: boolean;
    @Output() public readonly calculating = new EventEmitter<boolean>();
    @Input() public readonly startDateLabel : string = 'Start Date';
    @Input() public readonly disabled = false;

    // start: 'startDate' Two Way Binding
    private _startDate: string;
    /** for binding only - use 'startDateLocal' instead */
    @Input() private set startDate(val: string) {
        this._startDate = val;
    }
    @Output() private readonly startDateChange = new EventEmitter();
    /** use this instead of 'startDate' */
    public get startDateLocal(): string {
        return DateUtil.formatUtcDateToDateWithLocalTimeZone(this._startDate);
    }
    public set startDateLocal(val: string) {
        this.emitStartDateChange(val, this._startDate !== val);
    }
    // end

    // start: 'duration' Two Way Binding
    private _duration = 1;
    /** for binding only - use 'durationLocal' instead */
    @Input() private set duration(val: number) {
        this._duration = val;
    }
    @Output() private readonly durationChange = new EventEmitter();
    /** use this instead of 'duration' */
    public get durationLocal(): number {
        return this._duration;
    }
    public set durationLocal(val: number) {
        this.emitDurationChange(val, this._duration !== val);
    }
    // end

    public nonWorkingDays: INonWorkingDayDto[];
    public calculatingEndDate = false;
    public datesHaveChanged = false;
    public endDate: Date;
    public readonly MIN_DURATION = DateDurationCalculatorComponent.MinimumDuration;
    public readonly MAX_DURATION = DateDurationCalculatorComponent.MaximumDuration; // arbitrary maxium duration to prevent durationChanged loop crashing the browser

    private durationChangePromise: Promise<void> = new Promise(resolve => resolve());

    constructor(
        public readonly nonWorkingDayLogic: NonWorkingDayLogicService,
    ) {
    }
    public ngOnInit(): void {
        this.nonWorkingDayLogic
            .getNonWorkingDaysForLot(this.lotId)
            .subOnce(x => {
                this.nonWorkingDays = x;
                this.dateChanged();
            });
    }

    @Debounce(DEBOUNCE_DURATION)
    public emitDurationChange(val: number, updated: boolean): void {
        this.durationChange.emit(val);
        if (updated) {
            this.updated.next(null);
        }
    }

    @Debounce(DEBOUNCE_DURATION)
    public emitStartDateChange(val: string, updated: boolean): void {
        this.startDateChange.emit(val);
        if (updated) {
            this.updated.next(null);
        }
    }

    public dateChanged(): void {
        if (!this || !this.nonWorkingDays) {
            return;
        }
        this.calculatingEndDate = true;
        this.calculating.emit(this.calculatingEndDate);
        this.calculateDateChanged();
    }

    public dateFilter = (date: Date): boolean => {
        return this.nonWorkingDayLogic.nonWorkingDaysFilter(this.nonWorkingDays, date) &&
            this.nonWorkingDayLogic.weekDaysFilter(date);
    };

    @Debounce(DEBOUNCE_DURATION)
    private calculateDateChanged(): void {
        this.durationChangePromise.finally(() => {
            this.durationChanged();
            this.datesHaveChanged = true;
        });
    }

    public durationChanged(newDurationDays: number = +this.durationLocal): void {
        this.durationChangePromise = new Promise((resolve) => {
            let durationCounter = this.MIN_DURATION;

            const format = 'YYYY-MM-DD';
            const days = 'days';
            const hours = 'hours';
            const oneDay = 1;
            // hours in a work day
            const workDayHours = 9;

            // intialise end date at the end of the start day working day
            let newEndDate = moment(this.startDateLocal, format).add(workDayHours, hours);
            while (newDurationDays > durationCounter) {
                // adds a single day to the endDate
                newEndDate = newEndDate.add(oneDay, days);
                const endDate = newEndDate.toDate();
                if (this.nonWorkingDayLogic.weekDaysFilter(endDate) &&
                    this.nonWorkingDayLogic.nonWorkingDaysFilter(this.nonWorkingDays, endDate)) {
                    // increments durationCounter
                    durationCounter++;
                }
            }

            // set duration props to new duration
            this.durationLocal = newDurationDays;
            this.endDate = newEndDate.toDate();

            // done
            this.calculatingEndDate = false;
            this.calculating.emit(this.calculatingEndDate);
            this.change.emit();
            resolve();
        });
    }
}
