import { getBaseAutocompleteScrollerProvider, AutocompleteScrollerComponent } from '../../autocomplete-scroller.component';
import {Component, HostBinding, Input, OnChanges} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { IBusinessAccountSearchDto } from '@app/logic/business-accounts/interfaces/i.business-account-search.dto';
import { IBusinessAccountSearchParams } from '@app/logic/business-accounts/interfaces/i.business-account-search-params';
import { BusinessAccountsSearchService } from '@app/core/services/search/business-accounts-search.service';
import {
    BUSINESS_ACCOUNT_STATUS_ENUM,
    BusinessAccountSearchTypeEnumId,
    BusinessAccountStatusEnumId, IBusinessAccountDocumentDto
} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-business-account-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(BusinessAccountAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(BusinessAccountAutocompleteScrollerComponent),
        BusinessAccountsSearchService,
    ]
})
export class BusinessAccountAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<IBusinessAccountDocumentDto> implements OnChanges {
    @Input() public readonly searchType: BusinessAccountSearchTypeEnumId;
    @Input() public readonly tradeTypeId: number;
    @Input() public readonly status: BusinessAccountStatusEnumId[];
    @Input() public readonly regionIds: number[];
    @Input() public readonly districtId: number;
    @Input() public readonly suburbId: number;
    @Input() public readonly ratesOnly: boolean;
    @Input() public readonly explicitSearch: boolean;
    @Input() public readonly displayNonActiveStatus: boolean = false;
    @HostBinding('class') class = 'flex-row flex';

    constructor(
        public readonly dialog: MatDialog,
        public readonly searchService: BusinessAccountsSearchService
    ) {
        super(dialog, searchService);
    }

    public ngOnChanges(): void {
        this.searchService.extraSearchParams = {
            t: this.searchType,
            tt: this.tradeTypeId,
            s: this.status,
            reg: this.regionIds,
            dis: this.districtId,
            sub: this.suburbId,
            ro: this.ratesOnly,
            ex: this.explicitSearch,
        } as Partial<IBusinessAccountSearchParams>;
    }

    public displayWith(lookup: any): string {
        if(!lookup){
            return null;
        }
        let display = (lookup as IBusinessAccountSearchDto).tradingName || lookup.name || lookup.label;
        if(this.displayNonActiveStatus &&
            (lookup.status !== BUSINESS_ACCOUNT_STATUS_ENUM.Active &&
                lookup.status !== BUSINESS_ACCOUNT_STATUS_ENUM.Overridden)) {
            display += ` (${lookup.statusDisplay})`;
        }
        return display;
    }
}
