import { Component, OnInit, Inject } from '@angular/core';
import { LotPermissions } from '@app/core/permissions';
import { AddressRegionsLogicService } from '@app/logic/address-regions';
import { toPromisedArray } from 'cb-hub-lib';
import {
    BUSINESS_ACCOUNT_STATUS_ENUM,
    BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM,
    COST_NATURE_ENUM,
    MANUAL_ORDER_REASON_ENUM,
    CostNatureEnumId,
    IAddressRegionDto,
    ILotDto, IBuildActivityDto, IBusinessAccountDocumentDto, ISkinnyBuildActivityDto,
} from '@classictechsolutions/hubapi-transpiled-enums';
import { find, orderBy, remove } from 'lodash';
import { BaseFormViewDirective } from '@app/shared/base-views/base-form-view.directive';
import { IPurchaseOrderMappedItem } from '@app/logic/purchase-orders';
import { IPurchaseOrdersLogicService } from '@app/logic/purchase-orders/interfaces/i.purchase-orders.logic.service';
import { IPurchaseOrderDto } from '@app/logic/purchase-orders/interfaces/i.purchase-order.dto';
import { BusinessAccountLogicService, IBusinessAccountContactDto, IBusinessAccountDto } from '@app/logic/business-accounts';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BuildActivitiesLogicService } from '@app/logic/build-activities';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ViewManualOrderDialogComponent } from '../view-manual-order-dialog/view-manual-order-dialog.component';
import { IBuildStageDto, BuildStagesLogicService } from '@app/logic/build-stages';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import * as moment from 'moment';
import { DateDurationCalculatorComponent } from '@app/shared/components/date-duration-calculator/date-duration-calculator.component';


interface IData {
    lotDto: ILotDto;
    regionId: number;
    mappedItem: IPurchaseOrderMappedItem;
}

@Component({
    selector: 'cb-edit-manual-order-dialog',
    templateUrl: './edit-manual-order-dialog.component.html',
    styleUrls: ['./edit-manual-order-dialog.component.scss']
})
export class EditManualOrderDialogComponent extends BaseFormViewDirective<IPurchaseOrderDto, IPurchaseOrderMappedItem, IPurchaseOrdersLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '33%';

    public selectedSupplier: IBusinessAccountDocumentDto;
    public addressRegions: IAddressRegionDto[] = [];
    public selectedBuildActivity: IBuildActivityDto;
    public supplierContacts: IBusinessAccountContactDto[];
    public buildStages: IBuildStageDto[] = [];
    public costNatures: IEnumLookup<CostNatureEnumId>[] = [];
    public manualOrderReasonList = MANUAL_ORDER_REASON_ENUM.toSelectList();

    public selectedChargeableBusinessAccount: IBusinessAccountDto;
    public chargeableBusinessAccountContacts: IBusinessAccountContactDto[];
    public selectedChargeableContact: IBusinessAccountContactDto;

    public businessAccountSearchParams = {
        s: [
            BUSINESS_ACCOUNT_STATUS_ENUM.Active,
            BUSINESS_ACCOUNT_STATUS_ENUM.Overridden,
        ]
    };

    public get mappedItem(): IPurchaseOrderMappedItem {
        return this.data.mappedItem;
    }

    public get hasSupplier(): boolean {
        return this.mappedItem.supplierId > 0;
    }

    public get hasChargeableBusinessAccount(): boolean {
        return this.mappedItem.chargeableBusinessAccountId > 0;
    }

    public get lotDto(): ILotDto {
        return this.data.lotDto;
    }

    public get isTransformedSsr(): boolean {
        return this.mappedItem.ssrId > 0;
    }

    public get isOrderExtraToSupplier(): boolean {
        return this.mappedItem.costNature === COST_NATURE_ENUM.ExtraToSupplier;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<ViewManualOrderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LotPermissions) public readonly permisssions: LotPermissions,
        @Inject(AddressRegionsLogicService) public readonly addressRegionsLogicService: AddressRegionsLogicService,
        @Inject(BusinessAccountLogicService) public readonly businessAccountLogic: BusinessAccountLogicService,
        @Inject(BuildActivitiesLogicService) public readonly buildActivityLogic: BuildActivitiesLogicService,
        @Inject(BuildStagesLogicService) public readonly buildStageLogic: BuildStagesLogicService,
    ) {
        super(toastService);
        this.addressRegions = toPromisedArray(addressRegionsLogicService.$getList());
        this.buildStages = toPromisedArray(buildStageLogic.getBuildStageList(this.data.lotDto.id));
    }

    public ngOnInit(): void {
        if (this.mappedItem.id > 0) {
            this.mappedItem.$reload()
                .subOnce({ next: this.setupDetails });
        } else {
            this.setupDetails();
        }
    }

    public chargeableContactChanged(event: IBusinessAccountDto): void {
        this.mappedItem.freeTextChargeableBusinessAccountContact = null;
        this.mappedItem.chargeableBusinessAccountContactId = event.id;
    }

    public chargeableContactTextChanged(value: string): void {
        this.mappedItem.freeTextChargeableBusinessAccountContact = value;
        this.mappedItem.chargeableBusinessAccountContactId = null;
    }

    public displayContactWith(contact: IBusinessAccountDto): string | null {
        return contact && contact.name;
    }

    public addressRegionSelected(): void {
        const region = find(this.addressRegions, { id: this.mappedItem.supplierAddressRegionId });
        this.mappedItem.supplierAddressRegion = region ? region.name : '';
    }

    public clearSupplier(): void {
        this.selectedSupplier = null;
        this.supplierChanged();
    }

    public clearselectedChargeableBusinessAccount(): void {
        this.selectedChargeableBusinessAccount = null;
        this.chargeableBusinessAccountChanged();
    }

    public supplierChanged(supplier?: IBusinessAccountDocumentDto): void {
        if (supplier) {
            this.selectedSupplier = supplier;
        }
        if (!this.selectedSupplier) {
            this.mappedItem.resetSupplierDetails();
            this.mappedItem.supplierAddressRegionId = undefined;
            this.supplierContacts = [];
            return;
        }
        this.businessAccountLogic
            .$getItem(this.selectedSupplier.id)
            .subOnce({ next: this.handleMapSupplier });
    }

    public chargeableBusinessAccountChanged(): void {
        this.mappedItem.chargeableBusinessAccountId = this.selectedChargeableBusinessAccount.id;

        if (!this.selectedChargeableBusinessAccount) {
            this.chargeableBusinessAccountContacts = [];
            return;
        }
        this.businessAccountLogic
            .$getItem(this.selectedChargeableBusinessAccount.id)
            .subOnce({ next: this.handleMapChargeableBusinessAccount });
    }

    public selectedBuildActivityChanged(item: ISkinnyBuildActivityDto): void {
        if (!item) {
            return;
        }

        this.mappedItem.buildActivityId = item.id;
        this.mappedItem.activityCode = item.code;
        this.mappedItem.activityName = item.name;
    }

    public clearBuildActivity(): void {
        // this.selectedBuildActivity will be a string if the autocomplete field text is edited via user input
        if (typeof (this.selectedBuildActivity) === 'string') {
            this.mappedItem.buildActivityId = null;
            this.mappedItem.activityCode = null;
            this.mappedItem.activityName = null;
        }
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public handleSaveSuccess(result: IPurchaseOrderDto): void {
        this.dialogRef.close(result);
    }

    private readonly handleMapSupplier = (supplier: IBusinessAccountDto): void => {
        const address = this.mappedItem.resetSupplierDetails(supplier);
        this.mappedItem.supplierAddressRegionId = address.region;
        this.loadSupplierContacts(supplier.id);
    };

    private readonly handleMapChargeableBusinessAccount = (supplier: IBusinessAccountDto): void => {
        this.loadchargeableBusinessAccountContacts(supplier.id);
    };

    private readonly setupDetails = (item: IPurchaseOrderMappedItem = this.mappedItem): void => {
        this.setupSelectedSupplier(item.supplierId);
        this.selectAddressRegion(item.supplierAddressRegion);
        this.setupSelectedBuildActivity();
        this.loadCostNatures();
        this.setupSelectedChargeableBusinessAccount(item.chargeableBusinessAccountId);
        this.setOrderDateToDayStart();
    };

    private setOrderDateToDayStart(): void {
        this.mappedItem.orderDate = moment(this.mappedItem.orderDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }).format();
    }

    private loadCostNatures(): void {
        const costNatures = COST_NATURE_ENUM.toLookup();
        if (!this.lotDto.hasUnconditionalContract) {
            remove(costNatures, x => x.id === COST_NATURE_ENUM.ExtraToClient);
        }
        this.costNatures = costNatures;
    }

    private selectAddressRegion(regionName: string): void {
        const region = find(this.addressRegions, { name: regionName });
        if (!region) {
            this.addressRegions.unshift({ name: regionName } as any);
            return;
        }
        this.mappedItem.supplierAddressRegionId = region.id;
    }

    private setupSelectedSupplier(supplierId: number): void {
        if (!supplierId) {
            return;
        }
        this.selectedSupplier = { id: supplierId, tradingName: this.mappedItem.supplierName } as any;
        this.loadSupplierContacts(supplierId);
    }

    private setupSelectedChargeableBusinessAccount(supplierId: number): void {
        if (!supplierId) {
            return;
        }
        this.selectedChargeableBusinessAccount = { id: supplierId, tradingName: this.mappedItem.chargeableBusinessAccountName } as any;
        if (this.mappedItem.chargeableBusinessAccountContactId) {
            this.selectedChargeableContact = {
                id: this.mappedItem.chargeableBusinessAccountContactId,
                name: this.mappedItem.chargeableBusinessAccountContactName,
            } as IBusinessAccountContactDto;
        } else {
            this.selectedChargeableContact = {
                name: this.mappedItem.freeTextChargeableBusinessAccountContact
            } as IBusinessAccountContactDto;
        }

        this.loadchargeableBusinessAccountContacts(supplierId);
    }

    private setupSelectedBuildActivity(): void {
        if (!this.mappedItem.buildActivityId) {
            return;
        }

        this.selectedBuildActivity = {
            id: this.mappedItem.buildActivityId,
            code: this.mappedItem.activityCode,
            name: this.mappedItem.activityName
        } as IBuildActivityDto;
    }

    private loadSupplierContacts(supplierId: number): void {
        this.businessAccountLogic
            .getContactsByArea(supplierId, this.data.regionId, BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Orders)
            .subOnce({
                next: (res) => {
                    this.supplierContacts = orderBy(res, 'name');
                }
            });
    }

    private loadchargeableBusinessAccountContacts(supplierId: number): void {
        this.businessAccountLogic
            .getContactsByArea(supplierId, this.data.regionId, BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Orders)
            .subOnce({
                next: (res) => {
                    this.chargeableBusinessAccountContacts = orderBy(res, 'name');
                }
            });
    }

    public isDurationValid(): boolean {
        return this.mappedItem.activityDuration >= DateDurationCalculatorComponent.MinimumDuration &&
            this.mappedItem.activityDuration <= DateDurationCalculatorComponent.MaximumDuration;
    }

    public durationHasChanged(newDuration: number): void {
        this.mappedItem.activityDuration = newDuration;
    }
}
